/**
 * Swiper
 */
import Swiper from 'swiper';
import { MediaQuery } from 'foundation-sites/js/entries/plugins/foundation.util.mediaQuery';
import $ from 'jquery';
/*import merge from 'lodash/merge';*/

$(window).on('changed.zf.mediaquery', () => {
  // TODO: Use MediaQuery for breakpoint-matching below
  MediaQuery.current;
});

export default function piCarousel () {

  'use strict';

  const swiperVars = [];
  const selectorCarousel = $('.piCarousel');

  // breakpoint where swiper will be destroyed
  // and switches to a dual-column layout
  const isSmallDevice = () => {
    return MediaQuery.current === 'small';
  };

  const optionsKeyvisual = ($parent) => {
    return {
      spaceBetween: 10,
      slidesPerView: "auto",
      navigation: {
        nextEl: $('.swiper-button-next', $parent),
        prevEl: $('.swiper-button-prev', $parent)
      }
    };
  };

  const optionsContent = ($parent) => {
    return {
      keyboardControl: true,
      slidesOffsetBefore: 0,
      watchOverflow: true,
      slidesPerView: 1,
      autoHeight: true,
      navigation: {
        nextEl: $('.swiper-button-next', $parent),
        prevEl: $('.swiper-button-prev', $parent)
      },
    };
  };

  const createSwiperObject = function ($parent, index, type) {

    if (type === 'one-item') {
      swiperVars[index] = new Swiper($('.swiper-container', $parent), optionsContent($parent));
    }

    else {
      swiperVars[index] = new Swiper($('.swiper-container', $parent), optionsKeyvisual($parent));
    }
  };

  const enableSwiper = function () {
    selectorCarousel.each(function (index) {

      const $this = $(this);

      const carouselType = $this.data('type');
      $('.swiper-container', $this).addClass('swiper-' + index);

      if (carouselType === 'main-gallery') {
        const swiperWrapper = $this.prev('.piCarousel__reference');
        createSwiperObject($this, index, 'keyvisual');
      }

      if (carouselType === 'one-item') {
        createSwiperObject($this, index, 'one-item', 0);
      }

      if (carouselType === 'three-items' && breakpoint.matches === false) {
        createSwiperObject($this, index, 'three-items');
      }

      if (carouselType === 'two-items' && breakpoint.matches === false) {
        createSwiperObject($this, index, 'two-items');
      }

    });

  };

  const breakpointChecker = function () {

    selectorCarousel.each(function (index) {
      const $this = $(this);
        // destroy carousel for small breakpoints
      if (isSmallDevice()) {
          if (swiperVars[index]) {
            swiperVars[index].destroy(true, true);
            swiperVars[index] = void 0;
            $('.swiper-slide', $this).removeAttr('style');
          }
        }

        // create carousel for large breakpoints
        else if (!swiperVars[index] ) {
          createSwiperObject($this, index, 'main-gallery', 0);
        }
    });
  };

  enableSwiper();

  // keep an eye on viewport size changes
  $(window).on('changed.zf.mediaquery', breakpointChecker);
  // kickstart
  breakpointChecker();

  setTimeout(function() {
    selectorCarousel.each(function (index) {
      swiperVars[index].updateSize();
    })
  }, 1500);
}



