export default () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/sw.js').then(registration => {
        // eslint-disable-next-line no-console
        console.log('SW registered: ', registration);
      }).catch(registrationError => {
        // eslint-disable-next-line no-console
        console.warn('SW registration failed: ', registrationError);
      });
    });
  }

};
