/**
 * Entry-point for the global dependencies
 */

/*
 * Style imports
 */
import '../css/main.scss';

/*
 * Image imports
 */
import '../img/favicon-src.png';
import '../img/pfefferkorn-ingenieure.svg';
import '../img/logo-loading.svg';
import '../img/icon_facebook.svg';
import '../img/icon_instagram.svg';
import '../img/icon_linkedin.svg';
import '../img/icon_spotify.svg';
import '../img/icon_twitter.svg';
import '../img/icon_xing.svg';
import '../img/dropshadow_stickynav.png';
import '../img/404.gif';
import '../img/chevron.svg';
import '../img/chevron-down.svg';
import '../img/close.svg';
import '../img/filter.svg';

/*
 * Script imports
 */
import $ from 'jquery';
import 'lazysizes';
import gsap from 'gsap';
//import AOS from 'aos';
import barba from '@barba/core';

import piHeader from './components/header';
import piCarousel from './components/carousel';
import buildLoadingLayerController from './components/loading-layer';
import sendGoogleAnalyticsPageView from './components/analytics';
import initServiceWorker from './service-worker/sw-init';
import piProjectFilter from "./components/projectFilter";


/******************************************
 * own functions
 *****************************************/

const pageTransition = $('.page-transition');
const loaderIn = function () {
  // GSAP tween to stretch the loading screen across the whole screen
  return gsap.fromTo(
      pageTransition,
      {
        scaleX: 0,
        xPercent: -5,
        opacity: 1,
        autoAlpha: 1
      },
      {
        duration: 0.8,
        xPercent: 0,
        scaleX: 1,
        ease: 'power4.inOut',
        transformOrigin: 'left center',
        opacity: 1,
        autoAlpha: 1
      }
  );
};

const loaderAway = function () {
  // GSAP tween to hide loading screen
  return gsap.to(pageTransition, {
    duration: 0.8,
    scaleX: 0,
    xPercent: 5,
    transformOrigin: 'right center',
    ease: 'power4.inOut',
    opacity: 0,
    autoAlpha: 0
  });
};

const loaderReset = function () {
  gsap.set(pageTransition, {
    scaleX: 0,
    xPercent: -5,
    yPercent: -50,
    transformOrigin: 'left center'
  });
};

const setActiveNavEntry = function (nextPage) {
  $('header [data-nav-entry!=""]').removeClass('is-active');
  if (nextPage.match("^projekt-detail")) {
    nextPage = 'projekte';
  }
  $(`li[data-nav-entry=${nextPage}]`).addClass('is-active');
};



const initialFunctions = function () {
  /* Scroll to top from footer */
  $('#piScrollUp').click(() => {
    $('body,html').stop().animate({
      scrollTop: 0
    }, 400);
    return false;
  });

  $('.toggleFilter').click(function (event) {
    const $this = $(this);
    const target = $('#toggleFilterTarget');
    if (target.length) {
      event.preventDefault();
      target.toggleClass('active');
      $this.toggleClass('active');
    }
  });

  /* Smooth scroll to anchor on page */
  $('.piProject__outline span.link-underline').click(function (event) {
    const $this = $(this);
    const target = $($this.data('target'));
    if (target.length) {
      event.preventDefault();
      $('html, body').stop().animate({
        scrollTop: target.offset().top
      }, 600);
    }
  });

};

/******************************************
 * on first load
 *****************************************/

$(() => {
  piHeader();
  initialFunctions();
  /*AOS.init({
    delay: 50,
    duration: 600,
    offset: 80,
    once: true,
    easing: 'ease-in-out'
  });*/
});

if (process.env.ENVIRONMENT !== 'dev') {
  initServiceWorker();
} else {
  // eslint-disable-next-line no-console
  console.log(`Skipping registration of service worker as application is running with ENVIRONMENT [${process.env.ENVIRONMENT}]`);
}


/******************************************
 * Page transitions
 *****************************************/

const BARBA_REQUEST_TIMEOUT = 10000; // amount of time to wait before barba triggers a hard page-reload
const BARBA_LOADING_TIMEOUT = 1500; // amount of time to wait before showing the loading-layer
const loadingLayerControllerPromise = buildLoadingLayerController(
  '.loading-layer',
  'loading-layer--active',
  'loading-layer--opaque',
  BARBA_LOADING_TIMEOUT
);

barba.init({
  timeout: BARBA_REQUEST_TIMEOUT,
  requestError: (trigger, action) => {
    if (action === 'click') {
      loadingLayerControllerPromise.then((controller) => controller.activateOpaque());
    }
  },
  transitions: [
    {
      name: 'self',
      beforeEnter() {
        initialFunctions();
        console.log('same page');
      }
    },{
      name: 'fade',
      before () {
        loadingLayerControllerPromise.then((controller) => controller.activate());
      },
      afterLeave (data) {
        const mainClass = (data.next.container).dataset.mainclass;
        $('body').attr('style', '').attr('class', '').removeClass('init');
        $('main').attr('class', '').addClass(mainClass);
      },
      beforeEnter (data) {
        loadingLayerControllerPromise.then((controller) => controller.stopWaitForLoad());
        $('.piHeader.is-open').removeClass('is-open');
        $('html').removeClass('lock').removeClass('menu-open');
        $('.piHamburger.is-active').removeClass('is-active');
        $('.piNavFlyout.is-active').removeClass('is-active');

        $('body,html').delay(0).animate({
          scrollTop: 0
        }, 0);
        setActiveNavEntry(data.next.namespace);
      },
      async leave() {
        await loaderIn();
      },
      enter() {
        loaderAway();
      },
      afterEnter () {
        initialFunctions();
        loadingLayerControllerPromise.then((controller) => controller.deactivate());
      },
      after() {
        // we need to wait a tick as even in the after event the document title is not updated yet.
        setTimeout(sendGoogleAnalyticsPageView, 0);
      }
    },

  ],
  views: [
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'projekt-detail',
        beforeEnter() {
          import(/* webpackChunkName: "carousel" */ './components/carousel').then(({ default: enableSwiper }) => {
            enableSwiper();
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach(lifecycle => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    {
      namespace: 'contact',
      beforeEnter () {
        import(/* webpackChunkName: "map" */ './components/map').then(({ default: initMap }) => {
          initMap();
        });
      }
    },
    {
      namespace: 'projekte',
      beforeEnter () {
        import(/* webpackChunkName: "projekte" */ './components/projectFilter').then(({ default: projectFilter }) => {
          projectFilter();
        });
      }
    }
  ]
});
