/**
 * Project Filter
 */
import $ from 'jquery';

export default function piProjectFilter() {
  const selectorFilter = $('.piProjectFilter__select');
  selectorFilter.each(function () {
    const $this = $(this);
    // bind change event to select
    $this.on('change', function () {
      const selectUrl = $(this).val(); // get selected value
      if (selectUrl) { // require a URL
        window.location = selectUrl; // redirect
      }
      return false;
    });
  });
}