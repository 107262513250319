//////////////////////////////////////////////////////////////////
///// Menu  //////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

import $ from 'jquery';

export default function piHeader () {

  const toggleMenu = $('.piHeader__menu-toggle > .piHamburger');
  const header = $('.piHeader');
  const navFlyout = $('.piNavFlyout');
  /* eslint-disable-next-line no-unused-vars */
  let flyoutOpen = false;

  const closeFlyout = function () {
    toggleMenu.removeClass('is-active');
    navFlyout.removeClass('is-active');
    header.removeClass('is-open');
    $('html').removeClass('menu-open');
    flyoutOpen = false;
  };

  const openFlyout = function () {
    toggleMenu.addClass('is-active');
    navFlyout.addClass('is-active');
    header.addClass('is-open');
    $('html').addClass('menu-open');
    setTimeout(function (){
      flyoutOpen = true;
    }, 800);

  };

  toggleMenu.click(function (e) {
    e.preventDefault();
    const $this = $(this);
    if($this.hasClass('is-active')) {
      closeFlyout();
    }
    else {
      openFlyout();
    }
  });

  // Close menu on esc
  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      closeFlyout();
    }
  });

}
